import React from 'react'

import './CommingSoon.scss'

function CommingSoon({ text }) {
    return (

        <div className='CommingSoon'>
            {`<${text} /> Comming Soon..`}
        </div>
        // {/* <Timeline /> */}

    )
}

export default CommingSoon




// function Timeline() {
//     return (

//         <>
//             <div className='test'>
//                 <div>
//                     <span class="circle">
//                     </span>
//                     <span class="content">
//                     </span>
//                 </div>

//                 <div>
//                     <span class="circle">
//                     </span>
//                     <span class="content">
//                     </span>
//                 </div>
//             </div>

//         </>



//         // <div className="container">
//         //     <div className="timeline">
//         //         <div className="timeline-container primary">
//         //             <div className="timeline-icon">
//         //                 {/* <i className="far fa-grin-wink"></i> */}
//         //                 <i class="bi bi-apple"></i>
//         //             </div>
//         //             <div className="timeline-body">
//         //                 <p>TypeScript </p>
//         //                 <h4 className="timeline-title"><span className="badge">Primary</span></h4>
//         //                 {/* <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Aliquam necessitatibus numquam earum ipsa fugiat veniam suscipit, officiis repudiandae, eum recusandae neque dignissimos. Cum fugit laboriosam culpa, repellendus esse commodi deserunt.</p> */}
//         //             </div>
//         //         </div>
//         //         <div className="timeline-container danger">
//         //             <div className="timeline-icon">
//         //                 <i className="far fa-grin-hearts"></i>
//         //             </div>
//         //             <div className="timeline-body">
//         //                 <h4 className="timeline-title"><span className="badge">Danger</span></h4>
//         //                 <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Aliquam necessitatibus numquam earum ipsa fugiat veniam suscipit, officiis repudiandae, eum recusandae neque dignissimos. Cum fugit laboriosam culpa, repellendus esse commodi deserunt.</p>
//         //                 <p className="timeline-subtitle">2 Hours Ago</p>
//         //             </div>
//         //         </div>
//         //     </div>
//         // </div>
//     )
// }

// export default Timeline