import React from 'react';
import { Link } from "gatsby";
import './Logo.scss'

function Logo() {
    return (
        <span className="logo">
            <Link to="/">
                <span className="logo--1">
                    nabin
                </span>
                <span className="logo--2">
                    bhandari
                </span>
            </Link>
        </span>
    )
}

export default Logo;
