import React from 'react'
import { ThemeToggler } from 'gatsby-plugin-dark-mode'

import './Darkmode.scss'

function Darkmode() {
    // const [theme, setTheme] = useState('')
    // const mode = {
    //     light: 'bi-moon-fill',
    //     dark: 'bi-brightness-low-fill'
    // }
    // useEffect(() => {
    //     setTheme(document.body.classList[0])
    // }, [])
    return (
        <ThemeToggler>
            {({ theme, toggleTheme }) => (
                <label htmlFor="darkmode" className="switch">
                    <input
                        type="checkbox"
                        onChange={e => toggleTheme(e.target.checked ? 'dark' : 'light')}
                        checked={theme === 'dark'}
                        id="darkmode"
                    />
                    <span className="slider round"></span>
                </label>
            )}
        </ThemeToggler>
    )
}

export default Darkmode;
