import { Link } from "gatsby"
import * as React from "react"


import CommingSoon from "../components/Global/CommingSoon"


import Darkmode from '../components/Header/Darkmode'
import Logo from '../components/Header/Logo'


export default function FormPage() {
  // const [name, setName] = React.useState('')
  // const [email, setEmail] = React.useState('')
  // const [githubUsername, setGithubUsername] = React.useState('')

  // React.useEffect(() => {
  //   const url = "http://localhost:5000/users";
  //   async function getRes(url) {
  //     const response = await fetch(url, {
  //       method: "GET",
  //     });
  //     return response.json();
  //   }
  //   getRes(url)
  //     .then((res) => {
  //       alert(JSON.stringify(res))
  //     })
  //     .catch((error) => console.log(error));
  // }, [])


  // const sendData = (e) => {
  //   e.preventDefault();

  //   console.log('senddata 1');
  //   if (!name) return
  //   if (!email) return
  //   if (!githubUsername) return

  //   console.log('senddata 2');




  //   const url = "http://localhost:5000/user";

  //   async function getRes(url, data) {
  //     try {
  //       const response = await fetch(url, {
  //         method: "POST",
  //         headers: {
  //           'Content-Type': 'application/json'
  //         },
  //         // mode: 'no-cors', // no-cors, *cors, same-origin
  //         body: JSON.stringify(data),
  //       });
  //       return response.json();
  //     }
  //     catch (error) {
  //       console.log(error)
  //     }
  //   }

  //   getRes(url, {
  //     name: name,
  //     email: email,
  //     githubUsername: githubUsername
  //   })
  //     .then(data => alert(data))

  // }

  // const maindomain = (e) => {
  //   e.preventDefault();

  //   const demo = async (url, data) => {
  //     const response = await fetch(url, {
  //       method: 'POST', // *GET, POST, PUT, DELETE, etc.
  //       headers: {
  //         'Content-Type': 'application/json'
  //       },
  //       body: JSON.stringify(data) // body data type must match "Content-Type" header
  //     });
  //     return response.json(); // parses JSON response into native JavaScript objects
  //   }

  //   demo("http://localhost:5000/user", {
  //     name: name,
  //     username: githubUsername,
  //     email: email
  //   })
  //     .then(data => {
  //       console.log(data);
  //       setName('')
  //       setEmail('')
  //       setGithubUsername('')
  //       // JSON data parsed by `data.json()` call
  //     })
  // }

  return (
    <div>
      {/* <div></div> */}
      {/* <form method="POST" onSubmit={maindomain}>
        <label htmlFor="name">Name:</label>
        <input
          type="text"
          id="name"
          value={name || ``}
          onChange={(e) => setName(e.target.value)}
          required
        />


        <label htmlFor="email">email:</label>
        <input
          type="email"
          id="email"
          value={email || ``}
          onChange={(e) => setEmail(e.target.value)}
          required


        />

        <label htmlFor="githubusername">username:</label>
        <input
          type="githubusername"
          id="githubusername"
          value={githubUsername || ``}
          onChange={(e) => setGithubUsername(e.target.value)}
          required

        />

        <input type="submit" />


        {name}
        {email}
        {githubUsername}
      </form> */}


      <div className="section__404">
      <div className="section__404__nav">
        <Logo />
        <Darkmode />
      </div>
      <div>
   
      <CommingSoon text={'terminal'} />
      </div>
      <div className="btn--div">
        <Link className='btn-primary btn-v2' to="/">
          Go to home
          <i className="bi bi-arrow-right-short"></i>
        </Link>
      </div>
    </div>
      
    </div>
  )
}